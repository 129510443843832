export default {
  computed: {
    storeType() {
      // 通过 dictionaryFiledMap 获取数据字典中的 ZONE_TYPE_CODE ，返回格式[{value: code, label: name}]
      return this.$dictionaryFiledMap.ZONE_TYPE_CODE || [];
    },
    workType() {
      return this.$dictionaryFiledMap.ZONE_WORK_TYPE_CODE || [];
    },
    temperatureType() {
      // 通过 dictionary 获取数据字典中的 ZONE_TEMPERATURE_LAYER_CODE ，返回格式{dictionaryCode: dictionaryValue}
      return this.$dictionaryFiledMap.ZONE_TEMPERATURE_LAYER_CODE || [];
    },
  },
};
