import { FormType } from '@/constant/form';

// const  STORETYPE = await Store.dispatch('app/searchDictionaryByCode', 'ZONE_TYPE_CODE');
// const WORKTYPE = await Store.dispatch('app/searchDictionaryByCode', 'ZONE_WORK_TYPE_CODE');

const DICTIONARIES = {
  ADD: 'add',
  EDIT: 'edit',
  VIEW: 'view',
  STATUS: '1',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
};

const TABLECOLUMN = [
  {
    label: '序号',
    type: 'index',
    width: 50,
  }, {
    label: '仓库编码',
    prop: 'warehouseCode',
    minWidth: 120,
  }, {
    label: '库区编号',
    prop: 'zoneCode',
    minWidth: 120,
  }, {
    label: '库区名称',
    prop: 'zoneName',
    minWidth: 120,
  }, {
    label: '库区类型',
    prop: 'typeCode',
    minWidth: 120,
  }, {
    label: '温控类型',
    prop: 'temperatureLayerCode',
    minWidth: 120,
  }, {
    label: '作业类型',
    prop: 'workTypeCode',
    minWidth: 120,
  }, {
    label: '状态',
    prop: 'statusCode',
    minWidth: 120,
    formatter: (row) => (row.statusCode === DICTIONARIES.STATUS ? '是' : '否'),
  }, {
    label: '描述',
    prop: 'description',
    minWidth: 120,
  },
  {
    label: '操作',
    prop: 'action',
    fixed: 'right',
    width: 240,
  },
];

export function serchFields(resultStore, workStore) {
  return [
    {
      label: '库区编码',
      prop: 'zoneCode',
      component: FormType.INPUT,
    }, {
      label: '库区名称',
      prop: 'zoneName',
      component: FormType.INPUT,
    }, {
      label: '库区类型',
      prop: 'typeCode',
      component: FormType.SELECT,
      options: resultStore,
    }, {
      label: '作业类型',
      prop: 'workTypeCode',
      component: FormType.SELECT,
      options: workStore,
    },
  ];
}

export {
  TABLECOLUMN,
  DICTIONARIES,
};
