<template>
  <div>
    <nh-dialog
      v-model="visible"
      :title="info.title"
      :before-close="handleClose"
      :track="{
        name_zh: `基础数据/仓库相关/库区管理/弹窗-${
          info.status !== DICTIONARIES.ADD ? (info.status === 'view' ? '查看' : '编辑') : '新建'
        }`,
        trackName: $route.path
      }"
    >
      <el-form
        ref="editForm"
        :model="editForm"
        :rules="rules"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="库区编码" prop="zoneCode">
              <el-input
                v-if="info.status != 'view'"
                v-model="editForm.zoneCode"
                placeholder="请输入库区编码"
              />
              <p v-else>
                {{ editForm.zoneCode }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="库区名称" prop="zoneName">
              <el-input
                v-if="info.status != 'view'"
                v-model="editForm.zoneName"
                placeholder="请输入库区名称"
              />
              <p v-else>
                {{ editForm.zoneName }}
              </p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="作业类型:" prop="workTypeCode">
              <el-select
                v-if="info.status != 'view'"
                v-model="editForm.workTypeCode"
                placeholder="请选择..."
              >
                <el-option
                  v-for="item in workType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <p v-else>
                {{ filterWork(editForm.workTypeCode, workType) }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="库区类型:" prop="typeCode">
              <el-select
                v-if="info.status != 'view'"
                v-model="editForm.typeCode"
                placeholder="请选择..."
              >
                <el-option
                  v-for="item in storeType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <p v-else>
                {{ filterStore(editForm.typeCode, storeType) }}
              </p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="温层:" prop="temperatureLayerCode">
              <el-select
                v-if="info.status != 'view'"
                v-model="editForm.temperatureLayerCode"
                placeholder="请选择..."
              >
                <el-option
                  v-for="item in temperatureType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <p v-else>
                {{ filterTemperature(editForm.temperatureLayerCode, temperatureType) }}
              </p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="描述:" prop="description">
          <el-input
            v-if="info.status != 'view'"
            v-model="editForm.description"
            type="textarea"
            placeholder="请输入"
          />
          <p v-else>
            {{ editForm.description }}
          </p>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <nh-button @click="handleClose">关 闭</nh-button>
          <nh-button
            v-if="info.status != 'view'"
            @click="handleReset('editForm')"
          >重 置</nh-button>
          <nh-button
            v-if="info.status != 'view'"
            type="primary"
            :loading="loading.handleSubmit"
            @click="handleSubmit('editForm')"
          >确 定</nh-button>
        </span>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import { insert, detail, update } from '../api';
import { DICTIONARIES } from '../fileds';
import Mixin from '../mixin';

export default {
  name: 'WarehouseZoneEdit',
  mixins: [Mixin],
  emits: ['handleSubmit'],
  data() {
    return {
      DICTIONARIES,
      editForm: {
        zoneCode: undefined,
        zoneName: undefined,
        workTypeCode: undefined,
        typeCode: undefined,
        temperatureLayerCode: undefined,
        description: undefined,
      },
      editFormCope: {},
      rules: {
        zoneCode: [
          { required: true, message: '请输入库区编号', trigger: 'blur' },
        ],
        zoneName: [
          { required: true, message: '请输入库区名称', trigger: 'blur' },
        ],
        workTypeCode: [
          { required: true, message: '请选择作业类型', trigger: 'change' },
        ],
        typeCode: [
          { required: true, message: '请选择库区类型', trigger: 'change' },
        ],
        temperatureLayerCode: [
          { required: true, message: '请选择温层', trigger: 'change' },
        ],
      },
      info: {},
      visible: false,
      loading: {
        handleSubmit: false,
      },
    };
  },
  methods: {
    init(info) {
      this.info = {
        ...info,
      };
      this.visible = true;
      if (info.status !== DICTIONARIES.ADD) {
        this.getDetail(info.id);
      }
    },
    handleClose() {
      this.editForm = {
        zoneCode: undefined,
        zoneName: undefined,
        workTypeCode: undefined,
        typeCode: undefined,
        temperatureLayerCode: undefined,
        description: undefined,
      };
      this.$refs.editForm.resetFields();
      this.visible = false;
    },
    async getDetail(id) {
      const result = await detail(id);
      this.editForm = {
        ...result,
      };
      this.editFormCope = { ...this.editForm };
    },
    handleSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let data = {
            ...this.editForm,
            statusCode: DICTIONARIES.STATUS,
          };
          if (this.info.status === DICTIONARIES.EDIT) {
            data = {
              ...data,
              id: this.info.id,
            };
            await update(data);
          } else {
            await insert(data);
          }
          this.$emit('handleSubmit', this.info.status === DICTIONARIES.EDIT ? '编辑' : '新建');
          this.handleClose();
        }
      });
    },
    handleReset(formName) {
      if (this.info.status === DICTIONARIES.EDIT) {
        this.editForm = {
          ...this.editForm,
          ...this.editFormCope,
        };
      } else {
        this.$refs[formName].resetFields();
      }
    },
    filterWork(val, workType) {
      const result = workType.find((item) => item.value === val);
      return result && result.label;
    },
    filterStore(val, storeType) {
      const result = storeType.find((item) => item.value === val);
      return result && result.label;
    },
    filterTemperature(val, temper) {
      const result = temper.find((item) => item.value === val);
      return result && result.label;
    },
  },
};
</script>
