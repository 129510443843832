<template>
  <pl-block>
    <PlForm
      v-model="searchForm"
      mode="search"
      :fields="serchFields(storeType, workType)"
      @submit="handleSearch"
      @reset="handleSearch"
    />
  </pl-block>
  <pl-block>
    <nh-pro-table-header>
      <template #headerRight>
        <nh-button
          type="primary"
          plain
          :track="{
            trackName: '基础数据/仓库相关/库区管理/[新建]'
          }"
          @click="handleInsert"
        >
          新建
        </nh-button>
      </template>
    </nh-pro-table-header>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getReservoirList"
      :data="reservoirList"
      :columns="TABLECOLUMN"
    >
      <template #typeCode="{row}">
        <span>
          {{ filterStore(row.typeCode, storeType) }}</span>
      </template>
      <template #temperatureLayerCode="{row}">
        <span>
          {{ filtertemper(row.temperatureLayerCode, temperatureType) }}</span>
      </template>
      <template #workTypeCode="{row}">
        <span>
          {{ filterWork(row.workTypeCode, workType) }}</span>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="handleReservoirEdit(row)">
          编辑
        </nh-button>
        <nh-button type="text" @click="handleReservoirView(row)">
          查看
        </nh-button>
        <nh-button
          type="text"
          @click="handleReservoirStart(row)"
        >
          {{ row.statusCode === '1' ? '禁用' : '启用' }}
        </nh-button>
      </template>
    </PlTable>
  </pl-block>

  <Edit ref="edit" @handleSubmit="handleSubmit" />
</template>
<script>
import loadingMixin from '@thales/loading';
import Edit from './components/edit.vue';
import { TABLECOLUMN, DICTIONARIES, serchFields } from './fileds';
import { getList, setStatus } from './api';
import minxin from './mixin';

export default {
  name: 'WarehouseZone',
  components: {
    Edit,
  },
  mixins: [loadingMixin, minxin],
  data() {
    return {
      TABLECOLUMN,
      DICTIONARIES,
      serchFields,
      reservoirList: [],
      searchForm: {
        zoneCode: undefined,
        zoneName: undefined,
        typeCode: undefined,
        workTypeCode: undefined,
      },
      loading: {
        getReservoirList: false,
      },
    };
  },
  watch: {
    pagination() {
      this.getReservoirList();
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.getReservoirList();
    },
    async getReservoirList() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const result = await getList(this.searchForm, pagination);
      this.pagination.total = result.total;
      this.reservoirList = result.records;
    },
    handleSearch() {
      this.pagination.page = 1;
      this.getReservoirList();
    },
    handleReset(formName) {
      this.pagination.page = 1;
      this.$refs[formName].resetFields();
      this.getReservoirList();
    },
    handleSubmit(text) {
      this.$message({
        type: 'success',
        message: `${text}成功!`,
      });
      this.getReservoirList();
    },
    handleInsert() {
      const info = {
        title: '新建',
        status: DICTIONARIES.ADD,
      };
      this.$refs.edit.init(info);
    },
    handleReservoirEdit(row) {
      const info = {
        title: '编辑',
        status: DICTIONARIES.EDIT,
        id: row.id,
      };
      this.$refs.edit.init(info);
    },
    handleReservoirView(row) {
      const info = {
        title: '查看详情',
        status: DICTIONARIES.VIEW,
        id: row.id,
      };
      this.$refs.edit.init(info);
    },
    async handleReservoirStart(row) {
      try {
        await this.$confirm(
          `是否确认${row.statusCode === DICTIONARIES.STATUS ? '禁用' : '启用'}${row.zoneName}库区?`,
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          },
        );
        await setStatus({
          statusEnum:
            row.statusCode === DICTIONARIES.STATUS ? DICTIONARIES.DISABLED : DICTIONARIES.ENABLED,
          id: row.id,
        });
        this.$message({
          type: 'success',
          message: `${row.statusCode === DICTIONARIES.STATUS ? '禁用' : '启用'}成功!`,
        });
        this.pagination.page = 1;
        this.getReservoirList();
      } catch {
        this.$message({
          type: 'success',
          message: '你已取消!',
        });
      }
    },
    filtertemper(val, temperatureType) {
      const tempera = temperatureType && temperatureType.find((item) => item.value === val);
      return tempera && tempera.label;
    },
    filterStore(val, storeType) {
      const store = storeType && storeType.find((item) => item.value === val);
      return store && store.label;
    },
    filterWork(val, workType) {
      const work = workType && workType.find((item) => item.value === val);
      return work && work.label;
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
