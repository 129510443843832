import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 获取库区列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11164
 */
export function getList(data, params) {
  return wmsPlusHttp.post('/warehouse_foundation/zone/page', data, {
    params,
  });
}

/**
 * @description: 库区新增
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11156
 */
export function insert(data) {
  return wmsPlusHttp.post('/warehouse_foundation/zone/insert', data);
}

/**
 * @description: 库区编辑
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11158
 */
export function update(data) {
  return wmsPlusHttp.post('/warehouse_foundation/zone/update', data);
}

/**
 * @description: 库区禁用启动
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11166
 */
export function setStatus(params) {
  return wmsPlusHttp.post('/warehouse_foundation/zone/enable', null, { params });
}

/**
 * @description: 库区详情
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11160
 */
export function detail(id) {
  return wmsPlusHttp.get(`/warehouse_foundation/zone/get?id=${id}`);
}
